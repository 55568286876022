import React, { useState, useEffect } from 'react';
import ButtonBRK from '../components/buttons/ButtonBRK';
import ButtonInfo from '../components/buttons/ButtonInfo';
import ProductCapsuleSelectDrawer from '../drawers/ProductCapsuleSelectDrawer';
import ProductCapsuleSelect from '../components/UI/ProductCapsuleSelect';
import Backdrop from '../backdrop/Backdrop';
import Modal from '../modals/Modal';
import SummaryDrawer from '../drawers/SummaryDrawer';
import SummaryClientBag from '../components/UI/SummaryClientBag';
import OrderSteps from '../components/UI/OrderSteps';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { getProductsForAvailableCollections } from '../functions/products';
import {
  checkAddedProducts,
  calcTotalSubtotal,
  calcTotalLogos,
} from '../functions/qty-check';
import { useSelector, useDispatch } from 'react-redux';
import HeaderBRK from '../components/HeaderBRKmobile';
import HeaderBRKOrderPage from '../components/HeaderBRKOrderPage';
import ClientMobileMenuDrawer from '../drawers/ClientMobileMenuDrawer';
import BackdropMobile from '../backdrop/BackdropMobile';
import { toast, ToastContainer } from 'react-toastify';
import LoadingCapsulesImages from '../components/UI/LoadingCapsulesImage';
import {
  createOrder,
  confirmOrderById,
  getOrderById,
  updateOrderById,
} from '../functions/orders';
import OrderConfirmation from '../components/UI/OrderConfirmation';
import OrderConfirmationSuccess from '../components/UI/OrderConfirmationSuccess';
import ImageBigScale from '../components/UI/ImageBigScale';
import CapsulesListForOrders from '../components/UI/CapsulesListForOrders';
import { checkCollection } from '../functions/checkCollection';

function OrderPage() {
  const navigate = useNavigate();
  const params = useParams();
  //const { cart } = useSelector((state) => ({ ...state }));
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  const oid = params.oid;

  //============ STATES
  const [collectionId, setCollectionId] = useState('6787bc69380f39b3f46073b5');
  const [isProductCapsuleDrawerOpen, setIsProductCapsuleDrawerOpen] =
    useState(false);

  const [imageBigScaleInfo, setImageBigScaleInfo] = useState();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isImageBigScaleOpen, setIsImageBigScaleOpen] = useState(false);
  const [isSummaryDrawerOpen, setIsSummaryDrawerOpen] = useState(false);
  const [isSavingProgress, setIsSavingProgress] = useState(false);

  const [showHelp, setShowHelp] = useState(false);

  const [capsules, setCapsules] = useState([]);
  const [currentCapsule, setCurrentCapsule] = useState({});

  const [loading, setLoading] = useState(true);

  const [showOrderConfirmationHelp, setShowOrderConfirmationHelp] =
    useState(false);

  const [
    showOrderConfirmationSuccessHelp,
    setShowOrderConfirmationSuccessHelp,
  ] = useState(false);

  const [orderRef, setOrderRef] = useState('');

  //REDUX
  const dispatch = useDispatch();

  //Functions
  const addQty = (product, item) => {
    //item => size
    //console.log('Add qty', pid, item);
    dispatch({ type: 'ADD_QTY', payload: { product, size: item } });
    toast.success(`${product.name} / Size: ${item} added!`);
  };

  const reduceQty = (product, item) => {
    //display toast only if it wasnt zero
    const productExist = cart.find((p) => p.product._id === product._id);
    if (productExist) {
      const sizeAndQtyExist = productExist.pidOrder.find(
        (s) => s.size === item && s.qty !== 0
      );
      if (sizeAndQtyExist) {
        toast.warning(`${product.name} / Size: ${item} removed!`);
      }
    }
    //console.log('Reduce qty', pid, item);
    dispatch({ type: 'REDUCE_QTY', payload: { product, size: item } });
  };

  const removeProduct = (product, item) => {
    //item => size
    //display toast only if it wasnt zero
    const productExist = cart.find((p) => p.product._id === product._id);
    if (productExist) {
      toast.warning(`${product.name} removed!`);
    }
    dispatch({ type: 'REMOVE_FROM_CART', payload: { product, size: item } });
  };

  const addLogoToProduct = (product) => {
    dispatch({ type: 'ADD_LOGO', payload: { productId: product._id } });
    //check if logo in cart
    const isProductinCart = cart.find((p) => p.product._id === product._id);
    if (isProductinCart) {
      toast.success(`Logo added to ${product.name}!`);
    } else {
      toast.info(`${product.name} is not in your bag yet.`);
    }
  };

  const removeLogoToProduct = (product) => {
    dispatch({ type: 'REMOVE_LOGO', payload: { productId: product._id } });
    toast.warning(`Logo removed from ${product.name}!`);
  };

  // AbortController
  const abortController = new AbortController();

  //============ EFFECT
  useEffect(() => {
    // loadCapsules();
    loadProducts();
    loadOrder();
    //just in case
    setIsSavingProgress(false);

    // Cleanup function to abort fetching when component unmounts
    return () => abortController.abort();
  }, []);

  //============ FUNCTIONS
  // const loadProducts = () => {
  //   const resolveAfter3Sec = new Promise((resolve) =>
  //     setTimeout(resolve, 3000)
  //   );

  //   toast
  //     .promise(resolveAfter3Sec, {
  //       pending: 'Loading capsules...',
  //       success: 'Capsules loaded successfully',
  //       error: 'Error loading capsules',
  //       hideProgressBar: true,
  //     })
  //     .then(() => {
  //       getProductsForAvailableCollections(collectionId, user.token)
  //         .then((res) => {
  //           setCapsules(res.data.capsuleProductList);

  //           setTimeout(() => {
  //             setLoading(false);
  //           }, 1000);
  //         })
  //         .catch((err) => {
  //           console.error(err);
  //         });
  //     });
  // };
  const loadProducts = () => {
    getProductsForAvailableCollections(
      collectionId,
      user.token,
      abortController.signal
    )
      .then((res) => {
        setCapsules(res.data.capsuleProductList);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const loadOrder = () => {
    getOrderById(oid, user.token, abortController.signal)
      .then((res) => {
        if (res.data.message === 'Order does not exist') {
          toast.error('Could not find the order!');
          setTimeout(() => {
            navigate('/client-home-page');
          }, 1000);
        }
        dispatch({ type: 'LOAD_CART', payload: res.data.cart });
        setOrderRef(res.data.orderRef);
      })
      .catch((err) => {
        toast.error('Error');
        setTimeout(() => {
          navigate('/');
        }, 1000);
      });
  };

  const openProductDrawer = (index) => {
    setIsProductCapsuleDrawerOpen(true);
    setCurrentCapsule(capsules[index]);
  };

  const closeProductDrawer = () => {
    setIsProductCapsuleDrawerOpen(false);
    setIsSummaryDrawerOpen(false);

    //Autosave
    updateOrderById(oid, { newOrder: cart }, user.token)
      .then((res) => {
        if (res.data.message === 'Order already confirmed') {
          setIsSavingProgress(true);
          toast.info(
            'Ops, it looks like this order has already being confirmed.'
          );
          setTimeout(() => {
            navigate('/');
          }, 3000);
          return;
        }

        if (res.data.message === 'Wrong order') {
          toast.info('Wrong order.');
          setTimeout(() => {
            navigate('/');
          }, 2000);
          return;
        }

        toast.info('Auto saving', {
          position: 'bottom-left',
        });
      })
      .catch((err) => {
        setIsSavingProgress(false);
        console.log(err);
      });
  };

  const openSummaryDrawer = () => {
    setIsSummaryDrawerOpen(true);
  };

  const closeSummaryDrawer = () => {
    setIsSummaryDrawerOpen(false);
    //Autosave
    updateOrderById(oid, { newOrder: cart }, user.token)
      .then((res) => {
        if (res.data.message === 'Order already confirmed') {
          setIsSavingProgress(true);
          toast.info(
            'Ops, it looks like this order has already being confirmed.'
          );
          setTimeout(() => {
            navigate('/');
          }, 3000);
          return;
        }

        toast.info('Auto saving', {
          position: 'bottom-left',
        });
      })
      .catch((err) => {
        setIsSavingProgress(false);
        console.log(err);
      });
  };

  const openHelpHandler = () => setShowHelp(true);
  const closeHelpHandler = () => setShowHelp(false);

  const openOrderConfirmationHandler = () => setShowOrderConfirmationHelp(true);
  const closeOrderConfirmationHandler = () =>
    setShowOrderConfirmationHelp(false);

  const handleSaveProgress = () => {
    setIsSavingProgress(true);
    updateOrderById(oid, { newOrder: cart }, user.token)
      .then((res) => {
        if (res.data.message === 'Order already confirmed') {
          toast.info('This order has already being confirmed.');
          setTimeout(() => {
            navigate('/client-home-page');
          }, 2000);
          return;
        }

        if (res.data.message === 'Wrong order') {
          toast.info('Wrong order.');
          setTimeout(() => {
            navigate('/client-home-page');
          }, 2000);
          return;
        }
        const resolveAfter3Sec = new Promise((resolve) =>
          setTimeout(resolve, 2000)
        );
        toast
          .promise(resolveAfter3Sec, {
            pending: 'Saving progress',
            success: 'Progress saved',
            error: 'Something went wrong',
            hideProgressBar: false,
          })
          .then(() => {
            setTimeout(() => {
              navigate('/client-home-page');
              //Delete products from cart
              dispatch({ type: 'EMPTY_CART' });
            }, 1000);
          });
      })
      .catch((err) => {
        setIsSavingProgress(false);
        toast.error('Something went wrong. Please refresh the page.');
      });
  };

  //Functions
  const openMobileMenu = () => {
    setIsMobileMenuOpen(true);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const openImageBigScale = (product) => {
    setImageBigScaleInfo(product);
    setIsImageBigScaleOpen(true);
  };

  const closeImageBigScale = () => {
    setImageBigScaleInfo();
    setIsImageBigScaleOpen(false);
  };

  const confirmOrderHandler = () => {
    //setShowOrderConfirmationHelp(true);
    closeOrderConfirmationHandler();
    setIsSavingProgress(true);
    confirmOrderById({ orderId: oid }, user.token)
      .then((res) => {
        const resolveAfter3Sec = new Promise((resolve) =>
          setTimeout(resolve, 2000)
        );
        toast
          .promise(resolveAfter3Sec, {
            pending: 'Confirmation in progress',
            success: 'Order confirmed!',
            error: 'Something went wrong',
            hideProgressBar: false,
          })
          .then(() => {
            setTimeout(() => {
              setIsSavingProgress(false);
              setShowOrderConfirmationSuccessHelp(true);
              //Delete products from cart
              dispatch({ type: 'EMPTY_CART' });
            }, 2000);
          });
      })
      .catch((err) => {
        toast.error('Could not confirmed your order!');
        setIsSavingProgress(false);
      });
  };

  const navigateHome = () => {
    setShowOrderConfirmationSuccessHelp(false);
    closeHelpHandler();
    setTimeout(() => {
      navigate('/');
    }, 1000);
  };

  //============
  return (
    <React.Fragment>
      {/******************* COMMON - REFACTOR ***************************/}
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      {/* <ToastContainer /> */}
      {/* <Header /> */}
      {!loading && (
        <HeaderBRKOrderPage
          openSummaryDrawer={openSummaryDrawer}
          openMobileMenu={openMobileMenu}
          cart={cart}
        />
      )}

      <ClientMobileMenuDrawer show={isMobileMenuOpen} />
      {isMobileMenuOpen && <BackdropMobile onClick={closeMobileMenu} />}
      {/******************* COMMON - REFACTOR ***************************/}
      {isProductCapsuleDrawerOpen && <Backdrop onClick={closeProductDrawer} />}
      {isSummaryDrawerOpen && <Backdrop onClick={closeSummaryDrawer} />}
      {isSavingProgress && <Backdrop classes={'blur-backdrop'} />}

      <ProductCapsuleSelectDrawer show={isProductCapsuleDrawerOpen}>
        <ProductCapsuleSelect
          onClick={closeProductDrawer}
          capsule={currentCapsule}
          capsules={capsules}
          cart={cart}
          addQty={addQty}
          reduceQty={reduceQty}
          removeProduct={removeProduct}
          addLogoToProduct={addLogoToProduct}
          removeLogoToProduct={removeLogoToProduct}
          openImageBigScale={openImageBigScale}
        />
      </ProductCapsuleSelectDrawer>

      <SummaryDrawer show={isSummaryDrawerOpen}>
        <SummaryClientBag
          onClick={closeSummaryDrawer}
          cart={cart}
          addQty={addQty}
          reduceQty={reduceQty}
          removeProduct={removeProduct}
          addLogoToProduct={addLogoToProduct}
          removeLogoToProduct={removeLogoToProduct}
          capsules={capsules}
        />
      </SummaryDrawer>

      <Modal show={showHelp} onCancel={closeHelpHandler} header="Order steps">
        <OrderSteps navigate={navigate} />
      </Modal>

      <Modal
        show={showOrderConfirmationHelp}
        onCancel={closeOrderConfirmationHandler}
        header="Order confirmation"
      >
        <OrderConfirmation
          closeOrderConfirmationHandler={closeOrderConfirmationHandler}
          confirmOrderHandler={confirmOrderHandler}
        />
      </Modal>
      <Modal
        show={showOrderConfirmationSuccessHelp}
        onCancel={closeOrderConfirmationHandler}
        header="Thank you for your order!"
      >
        <OrderConfirmationSuccess navigateHome={navigateHome} />
      </Modal>

      {imageBigScaleInfo !== undefined && (
        <Modal
          show={isImageBigScaleOpen}
          onCancel={closeImageBigScale}
          className={'img-big-scale-modal'}
        >
          <ImageBigScale
            closeImageBigScale={closeImageBigScale}
            product={imageBigScaleInfo}
          />
        </Modal>
      )}

      {loading ? (
        <LoadingCapsulesImages />
      ) : (
        <>
          <div className="order-container">
            {/* <div className="testColorTheme"> */}
            <div className="exit-box">
              <div className="exit-container">
                <div className="exit-2-container" onClick={handleSaveProgress}>
                  <img
                    src="/images/exit-arrow.svg"
                    className="arrow-exit"
                    alt="arrow-exit"
                  />
                  <p>Save progress</p>{' '}
                  <span
                    style={{ fontStyle: 'italic' }}
                  >{` / Order (Ref. #${orderRef.slice(-6)})`}</span>
                </div>

                <h2>{checkCollection(collectionId)} Collection</h2>
              </div>
              <hr className="horizontal-line"></hr>
            </div>

            <CapsulesListForOrders
              //gender={gender}
              capsules={capsules}
              checkAddedProducts={checkAddedProducts}
              openProductDrawer={openProductDrawer}
              cart={cart}
            />

            <div className="total-amount-box">
              <hr className="horizontal-line"></hr>
              <div className="total-amount-flex-container">
                <div className="amount-item">
                  {calcTotalSubtotal(cart).discount ? (
                    <div className="flex-container-total-neto-discount">
                      <p>Total neto: </p>
                      <h3>
                        {calcTotalSubtotal(cart).totalAmount.toFixed(2)} €
                      </h3>
                      <h2>
                        {calcTotalSubtotal(cart).totalAmountDiscounted.toFixed(
                          2
                        )}{' '}
                        €
                      </h2>
                    </div>
                  ) : (
                    <p>
                      Total neto:{' '}
                      {calcTotalSubtotal(cart).totalAmount.toFixed(2)} €
                    </p>
                  )}

                  {calcTotalSubtotal(cart).discount ? (
                    <div className="flex-container-total-neto-discount">
                      <span>Subtotal:</span>
                      <span className="subtotal-crossed">
                        {calcTotalSubtotal(cart).subtotal.toFixed(2)}
                      </span>
                      <span>
                        {calcTotalSubtotal(cart).subtotalDiscounted.toFixed(2)}{' '}
                        € ( IVA:
                        {calcTotalSubtotal(cart).ivaTotalDiscounted.toFixed(
                          2
                        )}{' '}
                        €)
                      </span>
                    </div>
                  ) : (
                    <span>
                      Subtotal: {calcTotalSubtotal(cart).subtotal.toFixed(2)} €
                      ( IVA:
                      {calcTotalSubtotal(cart).ivaTotal.toFixed(2)} €)
                    </span>
                  )}

                  {calcTotalLogos(cart) > 0 && (
                    <span style={{ fontSize: '12px', fontWeight: '500' }}>
                      *Embroidery cost included
                    </span>
                  )}
                  {calcTotalSubtotal(cart).discount && (
                    <span
                      style={{
                        fontSize: '12px',
                        fontWeight: '500',
                        // color: 'red',
                      }}
                    >
                      *3% discount applied
                    </span>
                  )}

                  {/* <span>Logos: {calcTotalLogos(cart).toFixed(2)} €</span> */}
                </div>
                <div className="flex-container-amount-item-btn">
                  <div
                    className="capsule-name-flex-container"
                    onClick={openSummaryDrawer}
                  >
                    <img
                      src="/images/exit-arrow.svg"
                      className="arrow-exit"
                      alt="arrow-exit-reverse"
                    />
                    <p className="check-order-summary">Shopping bag</p>
                  </div>
                  <ButtonBRK
                    name="Confirm order"
                    onClick={openOrderConfirmationHandler}
                  />
                </div>
              </div>
            </div>
            <div className="flex-container-discounts-offer">
              <h2>Take advantage of our dicounts!</h2>
              <h3>For orders over 5.000 €, enjoy a 3% discount.</h3>
            </div>
            <div className="grid-container-how-to-proceed">
              <ButtonInfo name="Order steps" onClick={openHelpHandler} />
              <h2>Not sure how to proceed with the order?</h2>
            </div>
            {/* </div> */}

            {/* <div className="grid-container-color-keys-box">
              <h1 className="color-themes">Color themes</h1>
              <div className="grid-container-color-keys">
                <div className="flex-container-group-colors">
                  <h3>Emerald Oasis Collection</h3>
                  <div className="flex-container-colors">
                    <div className="rectangle r1-1"></div>
                    <div className="rectangle r1-2"></div>
                    <div className="rectangle r1-3"></div>
                  </div>
                </div>
                <div className="flex-container-group-colors">
                  <h3>Foggy Fern Collection</h3>
                  <div className="flex-container-colors">
                    <div className="rectangle r2-1"></div>
                    <div className="rectangle r2-2"></div>
                    <div className="rectangle r2-3"></div>
                  </div>
                </div>
                <div className="flex-container-group-colors">
                  <h3>Azure Breeze Collection</h3>
                  <div className="flex-container-colors">
                    <div className="rectangle r3-1"></div>
                    <div className="rectangle r3-2"></div>
                    <div className="rectangle r3-3"></div>
                  </div>
                </div>
              </div>
              <div className="grid-container-color-keys">
                <div className="flex-container-group-colors">
                  <h3>Sylvan Elegance Collection</h3>
                  <div className="flex-container-colors">
                    <div className="rectangle r4-1"></div>
                    <div className="rectangle r4-2"></div>
                    <div className="rectangle r4-3"></div>
                  </div>
                </div>
                <div className="flex-container-group-colors">
                  <h3>Citrus Twilight Collection</h3>
                  <div className="flex-container-colors">
                    <div className="rectangle r5-1"></div>
                    <div className="rectangle r5-2"></div>
                    <div className="rectangle r5-3"></div>
                  </div>
                </div>
                <div className="flex-container-group-colors">
                  <h3>Lilac Dream Collection</h3>
                  <div className="flex-container-colors">
                    <div className="rectangle r6-1"></div>
                    <div className="rectangle r6-2"></div>
                    <div className="rectangle r6-3"></div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <OtherWork /> */}
            {/* <div className="box-prints">
          <h1>Get to know some of the prints</h1>
          <div className="flex-container-prints">
            <img
              src="/images/brk-texture.svg"
              className="prints"
              alt="arrow-exit-reverse"
            />
            <img
              src="/images/Royal-liverpool.svg"
              className="prints"
              alt="arrow-exit-reverse"
            />
            <img
              src="/images/course.svg"
              className="prints"
              alt="arrow-exit-reverse"
            />
            <img
              src="/images/camu.svg"
              className="prints"
              alt="arrow-exit-reverse"
            />
          </div>
        </div> */}
          </div>
          <Footer />
        </>
      )}
    </React.Fragment>
  );
}

export default OrderPage;
