export const checkCollection = (collectionId) => {
  const collectionLibrary = {
    AW24: '6526a9adad300220c9b74da1',
    SS25: '665045c0a8c41588948cae27',
    AW25: '6787bc69380f39b3f46073b5',
    Stock: '6690d6a975b5086118edd145',
  };

  //6787bc69380f39b3f46073b5

  for (const [key, value] of Object.entries(collectionLibrary)) {
    if (String(value) === String(collectionId)) {
      return key;
    }
  }

  return null; // Return null if no matching ID is found
};
