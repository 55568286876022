import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import HeaderBRK from '../components/HeaderBRKmobile';
import ClientMobileMenuDrawer from '../drawers/ClientMobileMenuDrawer';
import BackdropMobile from '../backdrop/BackdropMobile';
import { useSelector } from 'react-redux';
import {
  getOrdersClient,
  createOrder,
  getOrderById,
} from '../functions/orders';
import { listCollections } from '../functions/collections';
import { toast, ToastContainer } from 'react-toastify';
import OrderDetails from '../components/UI/OrderDetails';
import OrderDetailsDrawer from '../drawers/OrderDetailsDrawer';
import Backdrop from '../backdrop/Backdrop';
import { homePageOrderTotal } from '../functions/qty-check';
import { listCapsules } from '../functions/capsules';
import { capFirstLetter } from '../functions/cap-first-letter';
import OtherWork from '../components/UI/OtherWork';
import ButtonBRK from '../components/buttons/ButtonBRK';
import ButtonInfo from '../components/buttons/ButtonInfo';
import InfoLoader from '../components/loaders/InfoLoader';
import { checkCollection } from '../functions/checkCollection';
import Modal from '../modals/Modal';
import StockClientPage from '../components/UI/StockClientPage';

function ClientHomePage() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [orders, setOrders] = useState([]);

  //======= Collection IDS =========
  //collection ss25
  const [collectionId, setCollectionId] = useState('6787bc69380f39b3f46073b5');
  //STOCK collection
  const [stockCollectionId, setStockCollectionId] = useState(
    '6690d6a975b5086118edd145'
  );
  //collection aw24
  //const [collectionId, setCollectionId] = useState('6526a9adad300220c9b74da1');
  //=================================
  const [collections, setCollections] = useState([]);
  const [showOrderDetails, setShowOrderDetails] = useState(false);

  //one holds the "cart" form of the order, the other the details
  const [individualOrder, setIndividualOrder] = useState([]);
  const [individualOrderDetails, setIndividualOrderDetails] = useState([]);
  const [capsules, setCapsules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isStockModalOpen, setIsStockModalOpen] = useState(false);

  //========== USE EFFECT =======================================
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    setLoading(true);
    loadOrders();
    loadCollections();
  }, []);
  //console.log('capsules client', capsules);

  const loadOrders = () => {
    const clientId = user._id;
    getOrdersClient(clientId, user.token)
      .then((res) => {
        setOrders(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        toast.error('Could not load your orders. Please refresh the page.');
        console.log(err);
      });
  };

  const loadCollections = () => {
    listCollections(user.token)
      .then((res) => {
        setCollections(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        toast.error('Could not load your orders. Please refresh the page.');
        console.log(err);
      });
  };

  //========== FUNCTIONS =======================================
  function checkClientOrder() {
    if (orders.length > 0) {
      return orders.map((order) => (
        <ul key={order._id}>
          {order.confirmed ? (
            <li className="flex-container-client-status">
              <h3>{`${checkCollection(
                order.pxCollection
              )} Collection: Your order (#${order.orderReference.slice(
                -6
              )}) is confirmed.`}</h3>
              <p onClick={() => openOrderDetails(order._id)}>See details.</p>
            </li>
          ) : (
            <li className="flex-container-client-status">
              <h3>{`You have an Order in progress`}.</h3>
              <div className="flex-container-status-items">
                {order.discount === 0 ? (
                  <h3>
                    ({`Total neto: ${homePageOrderTotal(order).totalAmount} €`})
                  </h3>
                ) : (
                  <h3>
                    (Total neto:{' '}
                    {/* <span style={{ textDecoration: 'line-through' }}>{`${
                      homePageOrderTotal(order).totalAmount
                    } €`}</span>{' '} */}
                    <span>{`${homePageOrderTotal(
                      order
                    ).totalAmountDiscounted.toFixed(2)} €`}</span>
                    )
                  </h3>
                )}

                <p
                  onClick={() =>
                    continueWithOrderHandler(order._id, order.pxCollection)
                  }
                >
                  Continue with order
                </p>
                {/* <p onClick={() => confirmOrderHandler(order._id)}>
                  Confirm order
                </p> */}
              </div>
            </li>
          )}
        </ul>
      ));
    } else {
      return (
        <ul>
          <li>(No orders found)</li>
        </ul>
      );
    }
  }

  const handleClick = () => {
    const checkOrderInProgress = isOrderOnGoing();

    if (checkOrderInProgress) {
      toast.info('You have an order in progress already.');
    } else {
      //1.- Create new order in db
      createOrder({ collectionId: collectionId }, user.token)
        .then((res) => {
          if (res.data.message === 'ok') {
            const resolveAfter3Sec = new Promise((resolve) =>
              setTimeout(resolve, 2000)
            );
            toast
              .promise(resolveAfter3Sec, {
                pending: 'Preparing collection',
                success: 'Collection prepared',
                error: 'Something went wrong. Please refresh the page.',
              })
              .then(() => {
                setTimeout(() => {
                  navigate(`/order/${res.data.orderId}`);
                  //Delete products from cart
                }, 1000);
              });
          } else {
            toast.error('Could not create new order. Please refresh the page.');
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Could not create new order (db)');
        });
    }
  };

  const handleStockOrderClick = () => {
    const checkOrderInProgress = isOrderOnGoing();

    if (checkOrderInProgress) {
      toast.info('You have an order in progress already.');
    } else {
      //1.- Create new order in db - id corresponding to Stock Collection
      createOrder({ collectionId: stockCollectionId }, user.token)
        .then((res) => {
          if (res.data.message === 'ok') {
            const resolveAfter3Sec = new Promise((resolve) =>
              setTimeout(resolve, 2000)
            );
            toast
              .promise(resolveAfter3Sec, {
                pending: 'Preparing collection',
                success: 'Collection prepared',
                error: 'Something went wrong. Please refresh the page.',
              })
              .then(() => {
                setTimeout(() => {
                  navigate(`/order-stock/${res.data.orderId}`);
                  //Delete products from cart
                }, 1000);
              });
          } else {
            toast.error('Could not create new order. Please refresh the page.');
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Could not create new order (db)');
        });
    }
  };

  //Functions
  const openMobileMenu = () => {
    setIsMobileMenuOpen(true);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const isOrderOnGoing = () => {
    //check if there is any order on going to handle the btn
    const isAnyOrderInPorgress = orders.find((o) => o.confirmed === false);

    if (isAnyOrderInPorgress) {
      return true;
    } else {
      return false;
    }
  };

  //collectionId is the collection the order belongs to
  //we need to either load collection order page or stock order oage

  const continueWithOrderHandler = (orderId, collectionId) => {
    getOrderById(orderId, user.token)
      .then((res) => {
        if (res.data.isOrderConfirmed) {
          toast.info('This order has already being confirmed.');
          loadOrders();
          return;
        }
        //if order has not being confirmed yet
        if (collectionId === stockCollectionId) {
          navigate(`/order-stock/${orderId}`);
        } else {
          navigate(`/order/${orderId}`);
        }
      })
      .catch((err) => {
        toast.error('Could not load your orders. Please refresh the page.');
      });
  };

  const closeOrderDetails = () => {
    setShowOrderDetails(false);
    setIndividualOrder([]);
    setIndividualOrderDetails();
  };

  const openOrderDetails = (orderId) => {
    //prepare the details
    let order = orders.find((o) => o._id === orderId);
    if (order) {
      setIndividualOrderDetails(order);
    } else {
      toast.error('Could not find the order!');
      return;
    }

    //prepare capsules
    listCapsules(user.token)
      .then((res) => {
        setCapsules(res.data.capsules);
      })
      .catch((err) => console.log(err));

    //prepare the products
    getOrderById(orderId, user.token)
      .then((res) => {
        setIndividualOrder(res.data.cart);
        setTimeout(() => setShowOrderDetails(true), 200);
      })
      .catch((err) => {
        toast.error('Could not load your orders. Please refresh the page.');
      });
  };

  const closeStockModal = () => setIsStockModalOpen(false);

  const openStockModal = (pid, name) => {
    setIsStockModalOpen(true);
  };

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/******************* COMMON - REFACTOR ***************************/}
      {/* <Header /> */}
      <HeaderBRK openMobileMenu={openMobileMenu} />
      <ClientMobileMenuDrawer show={isMobileMenuOpen} />
      {isMobileMenuOpen && <BackdropMobile onClick={closeMobileMenu} />}
      {/******************* COMMON - REFACTOR ***************************/}

      {showOrderDetails && <Backdrop onClick={closeOrderDetails} />}
      {individualOrderDetails && (
        <OrderDetailsDrawer show={showOrderDetails}>
          <OrderDetails
            order={individualOrder}
            orderDet={individualOrderDetails}
            closeOrderDetails={closeOrderDetails}
            capsules={capsules}
            collectionId={individualOrderDetails.pxCollection}
          />
        </OrderDetailsDrawer>
      )}

      {/* STOCK MODAL*/}

      <Modal
        show={isStockModalOpen}
        onCancel={closeStockModal}
        header={'Available Stock'}
      >
        <StockClientPage
          closeStockModal={closeStockModal}
          handleStockOrderClick={handleStockOrderClick}
          //loadProducts={loadProducts}
        />
      </Modal>

      <div className="client-home-page-box">
        <div className="exit-box">
          <div className="exit-container">
            <div className="exit-2-container">
              <img
                src="/images/exit-arrow.svg"
                className="arrow-exit"
                alt="arrow-exit"
              />
              <p onClick={() => navigate('/')}>Go back</p>{' '}
              <span style={{ fontStyle: 'italic' }}>{` / ${capFirstLetter(
                user.name
              )}`}</span>
            </div>

            <h2>{checkCollection(collectionId)} Collection</h2>
          </div>
          <hr className="horizontal-line"></hr>
        </div>

        {/* {orders && orders.length > 0 && ( */}

        {loading ? (
          <InfoLoader />
        ) : (
          <>
            <div className="order-history-details">
              <div className="ohd-pt1">
                <h2>Order history</h2>
                <div>{checkClientOrder()}</div>
              </div>
              <div className="btn-create-order-box">
                <ButtonInfo
                  name={`+ Create order ${checkCollection(collectionId)}`}
                  classes={'create-order-btn'}
                  onClick={handleClick}
                />
              </div>
            </div>

            {user.role === 'admin' && (
              <div className="order-history-details">
                <div className="ohd-pt1">
                  <h2 className="stock-title">Shop the stock</h2>
                  <div>
                    <h3>
                      Products in stock are ready for immediate delivery. The
                      delivery time varies between 1 to 3 days depending on
                      whether any embroidery needs to be applied to the items.
                    </h3>
                  </div>
                </div>
                <div className="btn-create-order-box">
                  <ButtonInfo
                    name={`Explore stock AW24`}
                    classes={'shop-stock-btn'}
                    onClick={openStockModal}
                  />
                </div>
              </div>
            )}
          </>
        )}

        <OtherWork />
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default ClientHomePage;
