import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import HeaderBRK from '../components/HeaderBRKmobile';
import ClientMobileMenuDrawer from '../drawers/ClientMobileMenuDrawer';
import BackdropMobile from '../backdrop/BackdropMobile';
import { useSelector } from 'react-redux';
import {
  getOrdersClient,
  createOrder,
  getOrderById,
} from '../functions/orders';
import { toast, ToastContainer } from 'react-toastify';
import OrderDetails from '../components/UI/OrderDetails';
import OrderDetailsDrawer from '../drawers/OrderDetailsDrawer';
import Backdrop from '../backdrop/Backdrop';
import { homePageOrderTotal } from '../functions/qty-check';
import { listCapsules } from '../functions/capsules';
import SectionBlock from '../components/UI/SectionBlock';
import OfficialSuppliers from '../components/UI/OfficialSuppliers';
//import pdf from './LookbookSS24.pdf';

const HIGHLIGHTS_COLLECTIONS = [
  {
    title: '01 Sustainability',
    body: 'Our commitment to sustainability runs deep. We integrate eco-friendly materials and practices, ensuring a greener approach to golf apparel without compromising style or performance.',
  },
  {
    title: '02 Quality',
    body: 'Crafted with the finest materials, our golf collection is a testament to uncompromising quality. We take pride in using top-grade polyamides, guaranteeing durability and comfort throughout your game.',
  },
  {
    title: '03 Tech +',
    body: 'Experience the fusion of technology and style in our collections. We incorporate cutting-edge advancements to enhance your golfing experience, from moisture-wicking fabrics to innovative designs for maximum mobility.',
  },
  {
    title: '04 Designed in Spain',
    body: 'Our golf collection is not just apparel; it is a piece of Spanish craftsmanship. Designed with passion and precision in Spain, each piece reflects our dedication to excellence and style in golf fashion.',
  },
  {
    title: '05 Price',
    body: 'Affordability meets excellence in our collections. We believe that high-quality golf apparel should be accessible to all. Explore our range where exceptional value meets exceptional performance.',
  },
  {
    title: '06 Service',
    body: 'Our commitment to you goes beyond the apparel. With exceptional service at the heart of our brand, we ensure your experience with us is as seamless as your swing on the course. Discover the difference in service with us.',
  },
];

const STRUCTURE = [
  {
    title: '01 Capsules',
    body: 'Discover our exclusive golf capsule collections, meticulously designed for both style and performance.',
  },
  {
    title: '02 Trends',
    body: 'Stay ahead of the curve with our latest golf trends. Our design philosophy blends fashion-forward aesthetics with functional excellence.',
  },
  {
    title: '03 Color',
    body: 'Explore a palette of rich, vibrant colors that reflect our commitment to both style and sustainability. Our color selections are carefully curated to enhance your golfing experience.',
  },
  {
    title: '04 Price',
    body: 'We believe in offering premium quality at an accessible price point.',
  },
];

const ESSENTIALS = [
  { title: '01 Essentials', body: '20% off for quantities above 50.' },
  {
    title: '02 Custom-made',
    body: 'We offer personalized collections. Let our design team help you.',
  },
  {
    title: '03 Staff',
    body: 'Elevate your teams attire and enjoy significant discounts on our garments.',
  },
  {
    title: '04 Own-label-collection',
    body: 'Choose own-label-collections for your products and give your customers a new experience.',
  },
];

function HomePagePlus() {
  //========== BACKGROUND IMAGE =======================================

  const imageStyle = {
    backgroundImage: `url("images/B2B-BANNER.png")`,
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '880px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    //backgroundAttachment: 'fixed',
    position: 'relative',
    backgroundColor: '#070707', // Adjust this value to set the desired opacity
    // Adjust this value to set the desired opacity

    // '@media (maxWidth: 850px)': {
    //   height: '200px', // Adjusted height for smaller screens
    // },
  };

  const mobileImageStyle = {
    backgroundImage: `url("images/B2B-BANNER-2.png")`,
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '680px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    //backgroundAttachment: 'fixed',
    position: 'relative',
    backgroundColor: '#070707', // Adjust this value to set the desired opacity
    // Adjust this value to set the desired opacity

    // '@media (maxWidth: 850px)': {
    //   height: '200px', // Adjusted height for smaller screens
    // },
  };

  const imageStyleStockBanner = {
    backgroundImage: `url("images/STOCK-BANNER-1.png")`,
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '940px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    //backgroundAttachment: 'fixed',
    position: 'relative',
    backgroundColor: '#070707', // Adjust this value to set the desired opacity
    // Adjust this value to set the desired opacity

    // '@media (maxWidth: 850px)': {
    //   height: '200px', // Adjusted height for smaller screens
    // },
  };

  const mobileImageStyleStockBanner = {
    backgroundImage: `url("images/STOCK-BANNER-1.png")`,
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '680px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    //backgroundAttachment: 'fixed',
    position: 'relative',
    backgroundColor: '#070707', // Adjust this value to set the desired opacity
    // Adjust this value to set the desired opacity

    // '@media (maxWidth: 850px)': {
    //   height: '200px', // Adjusted height for smaller screens
    // },
  };

  //========== CONST ==========================================
  const currentBrochure = 'AW25/26 COLLECTION';
  const stockTitle = 'AW24';

  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [collectionId, setCollectionId] = useState('6787bc69380f39b3f46073b5');
  //6787bc69380f39b3f46073b5
  const [showOrderDetails, setShowOrderDetails] = useState(false);

  //one holds the "cart" form of the order, the other the details
  const [individualOrder, setIndividualOrder] = useState([]);
  const [individualOrderDetails, setIndividualOrderDetails] = useState([]);
  const [capsules, setCapsules] = useState([]);

  const [currentStyle, setCurrentStyle] = useState(
    window.innerWidth <= 850 ? mobileImageStyle : imageStyle
  );

  const [currentStockStyle, setCurrentStockStyle] = useState(
    window.innerWidth <= 850
      ? mobileImageStyleStockBanner
      : imageStyleStockBanner
  );

  //========== USE EFFECT =======================================

  const loadOrders = () => {
    const clientId = user._id;
    getOrdersClient(clientId, user.token)
      .then((res) => {
        setOrders(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const handleResize = () => {
      setCurrentStyle(window.innerWidth <= 850 ? mobileImageStyle : imageStyle);
      setCurrentStockStyle(
        window.innerWidth <= 850
          ? mobileImageStyleStockBanner
          : imageStyleStockBanner
      );
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //========== FUNCTIONS =======================================
  function checkClientOrder() {
    if (orders.length > 0) {
      return orders.map((order) => (
        <ul key={order._id}>
          {!order.confirmed && (
            <li className="flex-container-client-status">
              <h3>AW24 Collection: You have an order in progress.</h3>
              <div className="flex-container-status-items">
                {order.discount === 0 ? (
                  <h3>
                    ({`Total neto: ${homePageOrderTotal(order).totalAmount} €`})
                  </h3>
                ) : (
                  <h3>
                    (Total neto:{' '}
                    <span style={{ textDecoration: 'line-through' }}>{`${
                      homePageOrderTotal(order).totalAmount
                    } €`}</span>{' '}
                    <span>{`${homePageOrderTotal(
                      order
                    ).totalAmountDiscounted.toFixed(2)} €`}</span>
                    )
                  </h3>
                )}

                <p onClick={() => continueWithOrderHandler(order._id)}>
                  Continue with order
                </p>
              </div>
            </li>
          )}
        </ul>
      ));
    }
  }

  const handleClick = () => {
    const checkOrderInProgress = isOrderOnGoing();

    if (checkOrderInProgress) {
      toast.info('You have an order in progress already.');
    } else {
      //1.- Create new order in db
      createOrder({ collectionId: collectionId }, user.token)
        .then((res) => {
          if (res.data.message === 'ok') {
            const resolveAfter3Sec = new Promise((resolve) =>
              setTimeout(resolve, 2000)
            );
            toast
              .promise(resolveAfter3Sec, {
                pending: 'Preparing collection',
                success: 'Collection prepared',
                error: 'Something went wrong. Please refresh the page.',
              })
              .then(() => {
                setTimeout(() => {
                  navigate(`/order/${res.data.orderId}`);
                  //Delete products from cart
                }, 1000);
              });
          } else {
            toast.error('Could not create new order. Please refresh the page.');
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Could not create new order (db)');
        });
    }
  };

  //Functions
  const openMobileMenu = () => {
    setIsMobileMenuOpen(true);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const isOrderOnGoing = () => {
    //check if there is any order on going to handle the btn
    const isAnyOrderInPorgress = orders.find((o) => o.confirmed === false);

    if (isAnyOrderInPorgress) {
      return true;
    } else {
      return false;
    }
  };

  const continueWithOrderHandler = (orderId) => {
    getOrderById(orderId, user.token)
      .then((res) => {
        if (res.data.isOrderConfirmed) {
          toast.info('This order has already being confirmed.');
          loadOrders();
          return;
        }
        //if order has not being confirmed yet
        navigate(`/order/${orderId}`);
      })
      .catch((err) => {
        toast.error('Could not load the order. Please refresh the page.');
      });
  };

  const closeOrderDetails = () => {
    setShowOrderDetails(false);
    setIndividualOrder([]);
    setIndividualOrderDetails();
  };

  const openOrderDetails = (orderId) => {
    //prepare the details
    let order = orders.find((o) => o._id === orderId);
    if (order) {
      setIndividualOrderDetails(order);
    } else {
      toast.error('Could not find the order!');
      return;
    }

    //prepare capsules
    listCapsules(user.token)
      .then((res) => {
        setCapsules(res.data.capsules);
      })
      .catch((err) => console.log(err));

    //prepare the products
    getOrderById(orderId, user.token)
      .then((res) => {
        setIndividualOrder(res.data.cart);
        setTimeout(() => setShowOrderDetails(true), 500);
      })
      .catch((err) => {
        toast.error('Could not load the order. Please refresh the page.');
      });
  };

  // console.log('orders', orders);
  // console.log('capsules', capsules);

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/******************* COMMON - REFACTOR ***************************/}
      {/* <Header /> */}
      <HeaderBRK
        classes={'dark-header'}
        location={'home'}
        openMobileMenu={openMobileMenu}
      />
      <ClientMobileMenuDrawer show={isMobileMenuOpen} />
      {isMobileMenuOpen && <BackdropMobile onClick={closeMobileMenu} />}
      {/******************* COMMON - REFACTOR ***************************/}

      {showOrderDetails && <Backdrop onClick={closeOrderDetails} />}
      {individualOrderDetails && (
        <OrderDetailsDrawer show={showOrderDetails}>
          <OrderDetails
            order={individualOrder}
            orderDet={individualOrderDetails}
            closeOrderDetails={closeOrderDetails}
            capsules={capsules}
          />
        </OrderDetailsDrawer>
      )}

      <div>
        <div className="custom-design-box">
          <div style={currentStyle}>
            <div className="flex-container-home-page-block-1">
              <div className="flex-container-home-page-title">
                <h1>{currentBrochure}</h1>
              </div>

              <div
                className="flex-container-new-collection-items"
                onClick={() => navigate('/client-home-page')}
              >
                <h2>Shop the collection</h2>
                <img
                  src="/images/white-arrow.svg"
                  className="arrow-exit-white-reverse"
                  alt="arrow"
                />
              </div>
            </div>
          </div>
        </div>
        {user.role === 'admin' && (
          <div className="custom-design-box">
            <div style={currentStockStyle}>
              <div className="flex-container-home-page-block-1">
                <div className="flex-container-home-page-title">
                  <h1>{stockTitle}</h1>
                </div>

                <div
                  className="flex-container-new-collection-items"
                  onClick={() => navigate('/client-home-page')}
                >
                  <h2>Shop the stock</h2>
                  <img
                    src="/images/white-arrow.svg"
                    className="arrow-exit-white-reverse"
                    alt="arrow"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* <div className="flex-container-home-page-block-1">
          <div className="flex-container-home-page-title">
            <h1>{currentBrochure}</h1>
          </div>

          <div
            className="flex-container-new-collection-items"
            onClick={() => navigate('/client-home-page')}
          >
            <h2>Create Order (SS25)</h2>
            <img
              src="/images/white-arrow.svg"
              className="arrow-exit-white-reverse"
              alt="arrow"
            />
          </div>

        
          <div className="flex-container-order-history">
            <div className="order-history">
           
              <div>{checkClientOrder()}</div>
            </div>
          </div>
        </div> */}

        <div className="download-brochure sticky-top-men-women">
          <h2 onClick={() => navigate('/product-page/men')}>Men</h2>

          <h2 onClick={() => navigate('/product-page/women')}>Women</h2>
        </div>

        {/* <div className="flex-container-home-page-block-2"> */}
        <SectionBlock
          title={'CORE VALUES'}
          subtitle={'COLLECTIONS'}
          header={'Six Key Pillars'}
          body={
            'From sustainability initiatives to top-grade polyamides, each collection embodies our commitment to excellence in golf fashion.'
          }
          // imageName={'/home-page/Ana-home-page.jpg'}
          imageName={'/home-page/Ana.jpg'}
          keyPoints={HIGHLIGHTS_COLLECTIONS}
        />

        <SectionBlock
          title={'STYLES FOR EVERYONE'}
          subtitle={'STRUCTURE'}
          header={'Your Style. Your Story'}
          body={
            'Express yourself on the golf course. Tell your unique golfing story through fashion.'
          }
          // imageName={'/home-page/Tara-home-page.jpg'}
          // imageName={'/home-page/Tara.jpg'}
          imageName={'/home-page/Carlos-Tara.jpg'}
          keyPoints={STRUCTURE}
        />

        <SectionBlock
          title={'ALL SEASON'}
          subtitle={'OTHER WORK'}
          header={'Must-have apparel for every golf player'}
          body={
            'Available essentials at unbeatable prices with big discounts. Polo-shirts, weather-resistant outerwear, and much more. These pieces will stand the test of time.'
          }
          imageName={'/home-page/Essentials.jpg'}
          keyPoints={ESSENTIALS}
        />

        <SectionBlock
          title={'OFFICIAL SUPPLIER'}
          subtitle={'EVENTS'}
          header={'Events we all love'}
          body={
            'As the official supplier for prestigious events such as The Open and the Solheim Cup, we take great pride in being a part of their storied history. These events hold a cherished place in the hearts of enthusiasts, and for us as well.'
          }
          imageName={'prod-the-open.jpg'}
          keyPoints={[]}
        />

        <OfficialSuppliers />
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default HomePagePlus;
