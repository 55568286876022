import React, { useEffect, useState } from 'react';
import { listClients } from '../../../functions/clients';
import { useSelector } from 'react-redux';
import { createOrderFromAdmin } from '../../../functions/orders';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

function CreateOrderFromAdmin() {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const user = useSelector((state) => state.user);

  useEffect(() => {
    loadClients();
  }, []);

  const loadClients = () => {
    listClients(user.token)
      .then((res) => {
        setClients(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createOrderForClient = (client) => {
    const isConfirmed = window.confirm(
      'Are you sure you want to create a new order?'
    );

    if (isConfirmed) {
      console.log('client', client);
      //1.- Create new order in db
      createOrderFromAdmin(
        { collectionId: '6787bc69380f39b3f46073b5', clientEmail: client.email },

        user.token
      )
        .then((res) => {
          if (res.data.message === 'ok') {
            const resolveAfter3Sec = new Promise((resolve) =>
              setTimeout(resolve, 2000)
            );
            toast
              .promise(resolveAfter3Sec, {
                pending: 'Preparing collection',
                success: 'Collection prepared',
                error: 'Something went wrong. Please refresh the page.',
              })
              .then(() => {
                setTimeout(() => {
                  navigate(`/order/${res.data.orderId}`);
                  //Delete products from cart
                }, 1000);
              });
          } else {
            toast.error('Could not create new order. Please refresh the page.');
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Could not create new order (db)');
        });
    }
  };

  return (
    <div className="flex-container-change-capsule">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <h2>Choose client</h2>
      <select
        value={selectedClient}
        className="styled-select"
        style={{ backgroundColor: 'white' }} // Inline style
        onChange={(e) => {
          const client = clients.find((c) => c._id === e.target.value);
          setSelectedClient(e.target.value);
          createOrderForClient(client);
        }}
      >
        <option value="" disabled>
          Select client
        </option>
        {clients.map((client) => (
          <option value={client._id} key={client._id}>
            {client.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default CreateOrderFromAdmin;
