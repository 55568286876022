function OtherWork() {
  return (
    <div className="other-work-box">
      <h2 className="offer-title">Unlock more with our expertise</h2>
      <div className="grid-container-other-work">
        <div className="flex-container-work">
          <img src="/images/design.svg" alt="img" className="img-work" />
          <h2>Custom-made</h2>
          <p>
            We offer personalized collections. Let our design team help you.
          </p>
        </div>
        <div className="flex-container-work">
          <img src="/images/custome.svg" alt="img" className="img-work" />
          <h2>Staff</h2>
          <p>
            Elevate your team's attire and enjoy significant discounts on our
            garments.
          </p>
        </div>
        <div className="flex-container-work">
          <img
            src="/images/label-collection.svg"
            alt="img"
            className="img-work"
          />
          <h2>Own-label-collection</h2>
          <p>
            Choose own-label-collections for your products and give your
            customers a new experience.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OtherWork;
